@import 'colours';

$primary: $teal;
$secondary: $teal; //selected in selectbox
$grid-header-text: $black;

@import "~@progress/kendo-theme-material/dist/all.scss";

// Grid
.k-header {
    font-size: 0.875rem ;
}

.k-filter-row th {
    padding: 0.3rem 0.5rem;
}

.k-grid-edit-row > td > .k-textbox {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.k-grid {
    overflow-x: auto;
}

.k-grid-header {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 10;
}

.k-grid-pager {
    position: sticky;
    bottom: 0;
    left: 0;
    z-index: 10;
}

// Kendo Upload
.k-upload {
    .k-dropzone {
        flex-direction: row-reverse;
        padding: 1rem 2rem;

        &-hint {
            text-align: left;
        }
    }

    .k-upload-button {
        background-color: $teal;
        color: $white;
        padding: 6px 16px;
        font-size: 0.875rem;
        min-width: 64px;
        box-sizing: border-box;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 500;
        line-height: 1.75;
        border-radius: 4px;
        letter-spacing: 0.02857em;
        text-transform: uppercase;
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

        &:hover {
            background-color: darken($teal, 10%);
            box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.20), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

            &::before {
                content: none;
            }
        }
    }
}

// Button Toolbar
.k-toolbar {
    border-color: rgba(0, 0, 0, 0.12);
    border-style: solid;
    border-width: 1px;
    box-shadow: none;
}
