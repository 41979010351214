@import 'variables';

@import "~bootswatch/dist/materia/variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/materia/bootswatch";

// Scrollbar  ==================================================================
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    &-thumb {
        background: #808080;
        border-radius: 3px;
    }
    &-track {
        background: #f5f5f5;
        border-radius: 10px;
    }
}

// Elements ====================================================================

button {
    -webkit-font-smoothing: antialiased;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
}

input {
    &:disabled {
        border-bottom: none !important;
        color: #616161 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        height: 1.5rem !important;
    }
}

h2 {
    font-size: 1.875rem !important;
    font-weight: 700 !important;
}

h3 {
    font-size: 1.5rem !important;
    font-weight: 700 !important;
}

h4 {
    font-size: 20px !important;
    font-weight: 600 !important;
    color: $black;
}

label {
    color: #000 !important;
    font-weight: 500 !important;
    padding-bottom: 0.2rem !important;
}

// FormIO Class Overrides ==================================================================

.formio-component-tabs {
    margin-bottom: 1rem;

    .card {
        border-radius: 0;

        .card-header {
            box-shadow: 0 1px 0 $gray-400;
            margin: 0;
            padding: 0;

            .nav-link {
                padding: 1rem 2rem;
                margin-bottom: 0;
                box-shadow: none;
                border-radius: 0;

                &.active, &:hover {
                    border: none;
                    color: $teal;
                    box-shadow: inset 0 -2px 0 $teal;
                }
            
                &.active {
                    background-color: $white;
                    font-weight: 500;
                }
            }
    
            &-tabs {
                margin: 0;
                padding: 0;
            }
        }
    }
}

.formio-dialog.formio-dialog-theme-default .formio-dialog-close:before {
    opacity: 1;
    position: initial;
}

.formio-dialog.formio-dialog-theme-default .formio-dialog-close {
    box-shadow: none;
    padding: 1px 6px;
}

.btn {
    font-weight: 500;
    box-shadow: $mui-button-shadow;
    padding: 0.375rem 1rem;
    border-color: initial;
    opacity: 1;
    font-size: 0.875rem;
    line-height: 1.75rem;

    &:hover {
        box-shadow: $mui-button-shadow-hover;
    }

    &:disabled {
        color: $gray-disabled-text;
        box-shadow: none;
        background-color: $gray-disabled;
        padding: 0.375rem 1rem;
        border: none;
    }

    &-secondary, &-default {
        &:not(:disabled) {
            border: 1px solid $primary;
            padding: 0.3125rem 0.9375rem;
            background-color: $white;
            color: $primary;

            &:not(:disabled):not([ref="dialogClose"]) {
                &:hover, &:active {
                    border: 1px solid $primary;
                    background-color: $primary;
                    color: $white;
                }
            }
        }
    }
}

.beca-form-section {
    margin-bottom: 4rem !important;
}

.beca-button-row {
    .fieldset-body {
        display: flex !important;
        flex-direction: row !important;
        justify-content: flex-end !important;

        button {
            margin-left: 1rem !important;
        }
    }
}

