@import 'colours';

// Fonts

$font-family-sans-serif: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif !default;
$font-size-base: .9375rem !default;
$font-size-sm: $font-size-base * .88 !default;
$headings-font-weight: 300 !default;

// Shadows

$mui-button-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
$mui-button-shadow-hover: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

// Buttons

$input-btn-padding-y: .8rem !default;
$input-btn-padding-x: 1rem !default;
$btn-padding-y-sm: .8rem !default;
$btn-padding-x-sm: .8rem !default;

$font-size-base: .9375rem !default;
$font-size-sm: $font-size-base * .88 !default;
$headings-font-weight: 300 !default;

$primary: $teal !default;
$secondary: $white !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $white !default;
$dark: $gray-800 !default;

$yiq-contrasted-threshold: 170 !default;

$enable-gradients: false !default;

// Body

$primary-colour: #3f51b5;
$secondary-colour: #f50057;
$bg: #3f51b5;