//
// Color system
//

$white: #fff !default;
$gray: #E0E0E0 !default;
$gray-disabled: rgba(0, 0, 0, 0.12) !default;
$gray-disabled-text: rgba(0, 0, 0, 0.26) !default;
$gray-100: #f5f5f5 !default;
$gray-200: #eeeeee !default;
$gray-300: #e0e0e0 !default;
$gray-400: #bdbdbd !default;
$gray-500: #9e9e9e !default;
$gray-600: #757575 !default;
$gray-700: #616161 !default;
$gray-800: #424242 !default;
$gray-900: #212121 !default;
$black: #000 !default;

$blue: #2196f3 !default;
$lightblue: #03a9f4 !default;
$indigo: #3f51b5 !default;
$purple: #9c27b0 !default;
$deeppurple: #673ab7 !default;
$pink: #e91e63 !default;
$red: #f44336 !default;
$orange: #ff9800 !default;
$yellow: #ffeb3b !default;
$amber: #ffc107 !default;
$green: #4caf50 !default;
$lightgreen: #8bc34a !default;
$lime: #cddc39 !default;
$teal: #0B6970 !default;
$cyan: #00bcd4 !default;

